import React from "react";
import Hero from "./Hero";
import Cv from "./Cv";
import Skills from "./Skills";
import Projects from "./Projects";
import Contact from "./Contact";

export default function Landing (){


    return (
        <>
        <Hero />
      <Cv />
      <Skills />
      <Projects />
      <Contact />
        </>

    )
}