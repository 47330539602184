import './App.css';
import { Flex } from '@chakra-ui/react';
import Landing from './Components/Landing';
import Tests from './Tests';
import { Route, Routes, Link } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Flex justifyContent='left' h='99vh' alignItems='center' p='40px' flexDirection='column' maxW='1200px' m='0 auto'>

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="tests" element={<Tests />} />
      </Routes>
      </Flex>
      
    </div>
  );
}

export default App;
