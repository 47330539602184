import React from "react";
import { useState } from "react";

export default function Tests (){

    const [counter, setCounter] = useState(0)

    // write a fn that takes to numbers and returns their sum

    function sumTwo(a, b){
        return a + b;
    }

    function getNames(obj){
        let names = []
    
        for(let i= 0; i< obj.length; i++){
            if(obj[i].hasOwnProperty('name')){
                names.push(obj[i].name)
            }
        }
            return names;
    }

    //Write a function that takes an array of numbers and returns the index of the largest number

        function getLargestNumber(array){
            let maxNumber = Math.max(...array);
            let maxIndex = array.indexOf(maxNumber)
            return maxIndex
        }

        //We use the spread operator (...) to unpack the values from the array and pass them as multiple, comma-separated arguments to the Math.max method.

        const handleClickplus = () => {
            return setCounter(counter + 1);
        }
        const handleClickminus = () => {
            return setCounter(counter - 1);
        }


        function segment(x, space) {
            // Write your code here
        
        //const result = new Array(Math.ceil(space.length / x)).fill().map(s => space.splice(0, x));
        
        // let result = [];
        //     for (let i = x; i > 0; i--) {
        //         result.push(space.splice(0, Math.ceil(space.length / i)));
        //     }
        
        const result = [];
            // for (let i = 0; i < space.length; i += x) {
            //     const chunk = space.slice(i, i + x);
            //     result.push(chunk);
            // }
            
         while (space.length > 0) {
                let chunk = space.splice(0, x);
                result.push(chunk);
            }
        
        let minSpaces = []
        
        for(var i=0; i< result.length; i++){
            minSpaces.push(Math.min(...result[i]))
        }
        
        return Math.max(...minSpaces)
        
        }

    return (
        <div>
        <h1>This is a test</h1>
        <p>SumTwo: {sumTwo(2,3)}</p> <br/>

        <p>getNames: { getNames([
            {a:1},
            {name: "Cristina"},
            {name: "Pablo"},
            {name: "Mirta"},
            {b:2},
        ])}</p>

        <p>Get largest number: <br/>{getLargestNumber([7, 1, 4, 12, 9]) }
        </p>

        <p>Space: <br/>{segment(2, [8,2,4,6, 7, 3]) }
        </p>

        <p >this is a counter: {counter}</p>
        <button onClick={() => handleClickplus()}>Increase 1</button>
        <button onClick={() => handleClickminus()}>Decrease 1</button>


        </div>

    )
}